<li class="rounded-sm border border-gray-200 px-1.5 py-1.5 shadow-sm sm:px-2">
  <!-- Start 1st line -->
  <div class="flex items-center justify-between space-x-3">
    <div class="flex items-center">
      <div
        class="w-8 shrink-0 py-0.5 text-center text-xs italic tracking-wide text-white antialiased shadow-md sm:w-10 sm:py-0 sm:text-sm"
        [style.backgroundColor]="setDocTypeColor(hit.doc_type)"
      >
        {{ hit.doc_type }}
      </div>
      <div class="flex items-center px-2">
        <a
          *ngIf="
            hit.content_type === 'web' &&
            hit.doc_no !== 'RAFC012' &&
            hit.doc_type !== 'EV' &&
            !isPDFLink
          "
          routerLink="/{{ hit.doc_type | lowercase }}/{{ hit.doc_no }}"
          [queryParams]="{ q: reformattedKeywords }"
          class="text-md font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-lg"
          [target]="isMobile ? '_self' : '_blank'"
          rel="noopener noreferrer"
          [innerHTML]="
            hit.name + ' (' + hit.hilighted_doc_no + ')' | sanitizeHtml
          "
        >
        </a>
        <a
          *ngIf="
            hit.content_type === 'web' &&
            hit.doc_no !== 'RAFC012' &&
            hit.doc_type !== 'EV' &&
            isPDFLink
          "
          href="/{{ locale }}/pdfs/{{ hit.doc_type | lowercase }}/{{
            hit.doc_no
          }}/{{ hit.pdf_path }}"
          class="text-md font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-lg"
          target="_blank"
          rel="noopener noreferrer"
          [innerHTML]="
            hit.name + ' (' + hit.hilighted_doc_no + ')' | sanitizeHtml
          "
        >
        </a>
        <a
          *ngIf="
            hit.content_type === 'web' && hit.doc_type === 'EV' && !isPDFLink
          "
          routerLink="/{{ hit.doc_type | lowercase }}/{{ hit.doc_no }}"
          [queryParams]="{ q: reformattedKeywords }"
          class="text-md font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-lg"
          [target]="isMobile ? '_self' : '_blank'"
          rel="noopener noreferrer"
          [innerHTML]="hit.hilighted_doc_no + hit.revision | sanitizeHtml"
        >
        </a>
        <a
          *ngIf="
            hit.content_type === 'web' && hit.doc_type === 'EV' && isPDFLink
          "
          href="/{{ locale }}/pdfs/{{ hit.doc_type | lowercase }}/{{
            hit.doc_no
          }}/{{ hit.pdf_path }}"
          class="text-md font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-lg"
          target="_blank"
          rel="noopener noreferrer"
          [innerHTML]="hit.hilighted_doc_no + hit.revision | sanitizeHtml"
        >
        </a>
        <a
          *ngIf="
            (hit.content_type === 'pdf' && hit.doc_type !== 'EV' && hit.name) ||
            (hit.content_type === 'web' &&
              hit.doc_no === 'RAFC012' &&
              hit.doc_type !== 'EV')
          "
          href="/{{ locale }}/pdfs/{{ hit.doc_type | lowercase }}/{{
            hit.doc_no
          }}/{{ hit.pdf_path }}"
          class="text-md font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-lg"
          target="_blank"
          rel="noopener noreferrer"
          [innerHTML]="
            hit.name + ' (' + hit.hilighted_doc_no + ')' | sanitizeHtml
          "
        >
        </a>
        <a
          *ngIf="
            hit.content_type === 'pdf' && hit.doc_type !== 'EV' && !hit.name
          "
          href="/{{ locale }}/pdfs/{{ hit.doc_type | lowercase }}/{{
            hit.doc_no
          }}/{{ hit.pdf_path }}"
          class="text-md font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-lg"
          target="_blank"
          rel="noopener noreferrer"
          [innerHTML]="hit.hilighted_doc_no | sanitizeHtml"
        >
        </a>
        <a
          *ngIf="hit.content_type === 'pdf' && hit.doc_type === 'EV'"
          href="/{{ locale }}/pdfs/{{ hit.doc_type | lowercase }}/{{
            hit.doc_no
          }}/{{ hit.pdf_path }}"
          class="text-md font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-lg"
          target="_blank"
          rel="noopener noreferrer"
          [innerHTML]="hit.hilighted_doc_no + hit.revision | sanitizeHtml"
        >
        </a>
        <div
          *ngIf="hit.content_type === 'pdf' || isPDFLink"
          class="mx-2 inline-block border border-gray-400 px-2 py-0.5 text-xs text-gray-500"
        >
          PDF
        </div>
      </div>
    </div>

    <div class="flex h-8 items-center space-x-0.5">
      <div class="hidden items-center sm:flex">
        <span class="text-base text-gray-500">
          {{ hit.publish_date | date : "MM/dd/yyyy" }}
        </span>
      </div>
      <div class="w-8 pl-2 text-gray-700" [ngSwitch]="hit.doc_type">
        <a
          *ngSwitchCase="'EV'"
          href="/{{ locale }}/pdfs/{{ hit.doc_type | lowercase }}/{{
            hit.doc_no
          }}/{{ hit.pdf_path }}"
          class="text-md font-medium text-bluemine-700 hover:text-white md:text-lg"
          target="_blank"
          rel="noopener noreferrer"
          (click)="sendPageView(locale, hit.doc_type, hit.doc_no, hit.pdf_path)"
        >
          <svg
            style="enable-background: new 0 0 128 128"
            viewBox="0 0 128 128"
            xml:space="preserve"
            class="h-5 w-5"
          >
            <path
              d="M104 126H24c-5.514 0-10-4.486-10-10V12c0-5.514 4.486-10 10-10h40.687a9.932 9.932 0 0 1 7.07 2.929l39.314 39.314a9.932 9.932 0 0 1 2.929 7.07V116c0 5.514-4.486 10-10 10zM24 6c-3.309 0-6 2.691-6 6v104c0 3.309 2.691 6 6 6h80c3.309 0 6-2.691 6-6V51.313a6.044 6.044 0 0 0-1.757-4.242L68.929 7.757A5.96 5.96 0 0 0 64.687 6H24z"
              style="fill: #fd4233"
            />
            <path
              d="M95.21 80.32c-.07-.51-.48-1.15-.92-1.58-1.26-1.24-4.03-1.89-8.25-1.95-2.86-.03-6.3.22-9.92.73-1.62-.93-3.29-1.95-4.6-3.18-3.53-3.29-6.47-7.86-8.31-12.89.12-.47.22-.88.32-1.3 0 0 1.98-11.28 1.46-15.1-.07-.52-.12-.67-.26-1.08l-.17-.44c-.54-1.25-1.6-2.57-3.26-2.5l-.98-.03h-.02c-1.86 0-3.36.95-3.76 2.36-1.2 4.44.04 11.09 2.29 19.69l-.58 1.4c-1.61 3.94-3.63 7.9-5.41 11.39l-.23.45c-1.88 3.67-3.58 6.79-5.13 9.43l-1.59.84c-.12.06-2.85 1.51-3.49 1.89-5.43 3.25-9.03 6.93-9.63 9.85-.19.94-.05 2.13.92 2.68l1.54.78c.67.33 1.38.5 2.1.5 3.87 0 8.36-4.82 14.55-15.62 7.14-2.32 15.28-4.26 22.41-5.32 5.43 3.05 12.11 5.18 16.33 5.18.75 0 1.4-.07 1.92-.21.81-.22 1.49-.68 1.91-1.3.82-1.23.98-2.93.76-4.67zM36.49 99.33c.7-1.93 3.5-5.75 7.63-9.13.26-.21.9-.81 1.48-1.37-4.32 6.89-7.21 9.63-9.11 10.5zM60.95 43c1.24 0 1.95 3.13 2.01 6.07.06 2.94-.63 5-1.48 6.53-.71-2.26-1.05-5.82-1.05-8.15 0 0-.05-4.45.52-4.45zm-7.3 40.14c.87-1.55 1.77-3.19 2.69-4.92 2.25-4.25 3.67-7.57 4.72-10.3 2.1 3.82 4.72 7.07 7.79 9.67.39.32.8.65 1.22.98-6.25 1.23-11.66 2.74-16.42 4.57zm39.43-.35c-.38.23-1.47.37-2.17.37-2.26 0-5.07-1.03-9-2.72 1.51-.11 2.9-.17 4.14-.17 2.27 0 2.94-.01 5.17.56 2.22.57 2.25 1.72 1.86 1.96z"
              style="fill: #ff402f"
            />
          </svg>
        </a>
        <a
          *ngSwitchDefault
          href="/{{ locale }}/pdfs/{{ hit.doc_type | lowercase }}/{{
            hit.doc_no
          }}/{{ hit.pdf_path }}"
          class="text-md font-medium text-bluemine-700 hover:text-white md:text-lg"
          target="_blank"
          rel="noopener noreferrer"
          (click)="sendPageView(locale, hit.doc_type, hit.doc_no, hit.pdf_path)"
        >
          <app-adobe-pdf-outline-icon
            svgClass="h-5 w-5 text-center"
          ></app-adobe-pdf-outline-icon>
        </a>
      </div>
      <div
        class="w-5 cursor-pointer"
        [ngClass]="{
          'text-gray-400': !isBookmarked,
          'text-blue-900': isBookmarked
        }"
        (click)="onClickAddBookmark($event, hit)"
      >
        <app-star-solid-icon
          svgClass="h-5 w-5 text-center"
        ></app-star-solid-icon>
      </div>
    </div>
  </div>
  <!-- End 1st line -->

  <!-- Start 2nd line -->
  <div>
    <p
      class="line-clamp-2 text-sm text-gray-600"
      [innerHTML]="hit.highlight"
    ></p>
  </div>
  <!-- End 2nd line -->

  <!-- Start 3rd line -->
  <div class="my-1 flex flex-row" *ngIf="isShowTags">
    <span
      class="h-24 w-24 shrink-0 rounded-md bg-gray-50 hover:translate-x-36 hover:translate-y-36 hover:scale-[4.0] hover:transform"
      [ngSwitch]="hit.doc_type"
    >
      <img
        *ngSwitchCase="'EV'"
        src="/images/thumbnails/{{ hit.doc_type | lowercase }}/{{
          hit.doc_no
        }}.jpg"
        class="h-24 w-24 rounded-md border border-gray-200 object-contain shadow-sm hover:border-gray-100"
      />
      <img
        *ngSwitchDefault
        src="/images/thumbnails/{{ hit.doc_type | lowercase }}/{{
          hit.doc_no
        }}-{{ locale }}.jpg"
        class="h-24 w-24 rounded-md border border-gray-200 object-contain shadow-sm hover:border-gray-100"
      />
    </span>
    <span class="px-0.5">
      <div class="py-0.5 pl-1.5 text-xs text-gray-500 sm:hidden">
        {{ hit.publish_date | date : "MM/dd/yyyy" }}
      </div>

      <ng-container *ngFor="let tag of hit.tags; index as i">
        <span
          *ngIf="i < 10 || more"
          class="mx-0.5 my-0.5 inline-flex items-center justify-items-start rounded-full border border-gray-300 py-0.5 pl-1 pr-2 text-xs font-normal uppercase tracking-wider text-gray-600 shadow-sm"
        >
          <div
            class="mr-0.5 inline-flex h-3 w-3 flex-shrink-0 items-center justify-center rounded-full border border-gray-400 shadow-md"
            [ngClass]="{
              'series-color': tag.category === 'series',
              'bg-blue-500': tag.category === 'generation',
              'bg-blue-100': tag.category === 'model',
              'bg-green-500': tag.category === 'component'
            }"
          ></div>
          {{ tag.name }}
        </span>
      </ng-container>
      <span *ngIf="hit.tags.length > 10" class="inline-flex px-2">
        <a (click)="more = !more" href="javascript:void(0)">
          <p class="flex items-center text-sm text-gray-900">
            {{ more ? showLess : showMore }}
            <app-chevron-down-outline-icon
              [ngClass]="{
                'rotate-180 transform': more,
                '': !more
              }"
              class="px-1 font-medium text-gray-900 transition duration-300 ease-in-out"
              svgClass="flex-shrink-0
            h-3
            w-3"
            ></app-chevron-down-outline-icon>
          </p>
        </a>
      </span>
    </span>
  </div>
  <!-- End 3rd line -->
</li>
