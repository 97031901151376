<li class="rounded-sm border border-gray-200 px-1.5 py-2 shadow-sm sm:px-2">
  <!-- Start 1st line -->
  <div class="flex justify-between space-x-3">
    <div class="flex items-center">
      <div
        class="w-8 shrink-0 py-0.5 text-center text-xs italic tracking-wide text-white antialiased shadow-md sm:w-10 sm:py-0 sm:text-sm"
        [style.backgroundColor]="setDocTypeColor(bookmark.doc_type)"
      >
        {{ bookmark.doc_type }}
      </div>
      <div class="flex items-center px-2">
        <a
          *ngIf="
            bookmark.content_type === 'web' &&
            bookmark.doc_type !== 'EV' &&
            !isPDFLink
          "
          href="/{{ bookmark.locale }}/{{ bookmark.doc_type | lowercase }}/{{
            bookmark.doc_no
          }}"
          class="text-sm font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-base md:leading-5"
          [target]="isMobile ? '_self' : '_blank'"
          rel="noopener noreferrer"
          [innerHTML]="
            bookmark.name + ' (' + bookmark.doc_no + ')' | sanitizeHtml
          "
        >
        </a>
        <a
          *ngIf="
            bookmark.content_type === 'web' &&
            bookmark.doc_type !== 'EV' &&
            isPDFLink
          "
          href="/{{ bookmark.locale }}/pdfs/{{
            bookmark.doc_type | lowercase
          }}/{{ bookmark.doc_no }}/{{ bookmark.pdf_path }}"
          class="text-sm font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-base md:leading-5"
          target="_blank"
          rel="noopener noreferrer"
          [innerHTML]="
            bookmark.name + ' (' + bookmark.doc_no + ')' | sanitizeHtml
          "
        >
        </a>
        <a
          *ngIf="
            bookmark.content_type === 'web' &&
            bookmark.doc_type === 'EV' &&
            !isPDFLink
          "
          href="/{{ bookmark.locale }}/{{ bookmark.doc_type | lowercase }}/{{
            bookmark.doc_no
          }}"
          class="text-sm font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-base md:leading-5"
          [target]="isMobile ? '_self' : '_blank'"
          rel="noopener noreferrer"
          [innerHTML]="bookmark.doc_no | sanitizeHtml"
        >
        </a>
        <a
          *ngIf="
            bookmark.content_type === 'web' &&
            bookmark.doc_type === 'EV' &&
            isPDFLink
          "
          href="/{{ bookmark.locale }}/pdfs/{{
            bookmark.doc_type | lowercase
          }}/{{ bookmark.doc_no }}/{{ bookmark.pdf_path }}"
          class="text-sm font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-base md:leading-5"
          target="_blank"
          rel="noopener noreferrer"
          [innerHTML]="bookmark.doc_no | sanitizeHtml"
        >
        </a>
        <a
          *ngIf="
            bookmark.content_type === 'pdf' &&
            bookmark.doc_type !== 'EV' &&
            bookmark.name
          "
          href="/{{ bookmark.locale }}/pdfs/{{
            bookmark.doc_type | lowercase
          }}/{{ bookmark.doc_no }}/{{ bookmark.pdf_path }}"
          class="text-sm font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-base md:leading-5"
          target="_blank"
          rel="noopener noreferrer"
          [innerHTML]="
            bookmark.name + ' (' + bookmark.doc_no + ')' | sanitizeHtml
          "
        >
        </a>
        <a
          *ngIf="
            bookmark.content_type === 'pdf' &&
            bookmark.doc_type !== 'EV' &&
            !bookmark.name
          "
          href="/{{ bookmark.locale }}/pdfs/{{
            bookmark.doc_type | lowercase
          }}/{{ bookmark.doc_no }}/{{ bookmark.pdf_path }}"
          class="text-sm font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-base md:leading-5"
          target="_blank"
          rel="noopener noreferrer"
          [innerHTML]="bookmark.doc_no | sanitizeHtml"
        >
        </a>
        <a
          *ngIf="bookmark.content_type === 'pdf' && bookmark.doc_type === 'EV'"
          href="/{{ bookmark.locale }}/pdfs/{{
            bookmark.doc_type | lowercase
          }}/{{ bookmark.doc_no }}/{{ bookmark.pdf_path }}"
          class="text-sm font-medium leading-5 text-blue-500 hover:text-bluemine-500 md:text-base md:leading-5"
          target="_blank"
          rel="noopener noreferrer"
          [innerHTML]="bookmark.doc_no | sanitizeHtml"
        >
        </a>
      </div>
    </div>

    <div class="flex items-center space-x-0.5">
      <div
        class="w-5 cursor-pointer"
        [ngClass]="{
          'text-gray-400': !isBookmarked,
          'text-blue-900': isBookmarked
        }"
        (click)="onClickBookmark($event, bookmark)"
      >
        <app-star-solid-icon
          svgClass="h-5 w-5 text-center"
          [stroke]="isBookmarked ? 3 : 1"
        ></app-star-solid-icon>
      </div>
    </div>
  </div>
  <!-- End 1st line -->

  <!-- Start 3rd line -->
  <div class="flex flex-row pb-0.5 pt-1" *ngIf="isShowTags">
    <span
      class="h-20 w-20 shrink-0 rounded-md bg-gray-50 hover:translate-x-28 hover:translate-y-28 hover:scale-[4.0] hover:transform"
      [ngSwitch]="bookmark.doc_type"
    >
      <img
        *ngSwitchCase="'EV'"
        src="/images/thumbnails/{{ bookmark.doc_type | lowercase }}/{{
          bookmark.doc_no
        }}.jpg"
        class="h-20 w-20 rounded-md border border-gray-200 object-contain shadow-sm hover:border-gray-100"
      />
      <img
        *ngSwitchDefault
        src="/images/thumbnails/{{ bookmark.doc_type | lowercase }}/{{
          bookmark.doc_no
        }}-{{ locale }}.jpg"
        class="h-20 w-20 rounded-md border border-gray-200 object-contain shadow-sm hover:border-gray-100"
      />
    </span>
    <span class="flex flex-col px-2">
      <div class="inline-block space-x-1 py-0.5 text-sm text-gray-500">
        <span
          *ngIf="bookmark.content_type === 'pdf' || isPDFLink"
          class="inline-block w-10 border border-gray-400 px-0.5 py-0.5 text-center text-xs text-gray-500"
        >
          PDF
        </span>
        <span class="">
          {{ publicLocales[bookmark.locale!] }}
        </span>
      </div>

      <div class="w-8 py-0.5 text-gray-700" [ngSwitch]="bookmark.doc_type">
        <a
          *ngSwitchCase="'EV'"
          href="/{{ locale }}/pdfs/{{ bookmark.doc_type | lowercase }}/{{
            bookmark.doc_no
          }}/{{ bookmark.pdf_path }}"
          class="text-md font-medium text-bluemine-700 hover:text-white md:text-base"
          target="_blank"
          rel="noopener noreferrer"
          (click)="
            sendPageView(
              locale,
              bookmark.doc_type,
              bookmark.doc_no,
              bookmark.pdf_path
            )
          "
        >
          <app-adobe-pdf-outline-icon
            svgClass="h-5 w-5 text-center"
          ></app-adobe-pdf-outline-icon>
        </a>
        <a
          *ngSwitchDefault
          href="/{{ locale }}/pdfs/{{ bookmark.doc_type | lowercase }}/{{
            bookmark.doc_no
          }}/{{ bookmark.pdf_path }}"
          class="text-md font-medium text-bluemine-700 hover:text-white md:text-base"
          target="_blank"
          rel="noopener noreferrer"
          (click)="
            sendPageView(
              locale,
              bookmark.doc_type,
              bookmark.doc_no,
              bookmark.pdf_path
            )
          "
        >
          <app-adobe-pdf-outline-icon
            svgClass="h-5 w-5 text-center"
          ></app-adobe-pdf-outline-icon>
        </a>
      </div>
    </span>
  </div>
  <!-- End 3rd line -->
</li>
